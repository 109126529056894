export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/auth/google-oauth-success-redirect": [5],
		"/complete-registration": [6],
		"/dashboard": [7,[2]],
		"/dashboard/copy-trade": [8,[2]],
		"/dashboard/intro": [9,[2]],
		"/dashboard/orders": [10,[2]],
		"/dashboard/positions": [11,[2]],
		"/dashboard/referral-dashboard": [12,[2]],
		"/dashboard/report-a-bug": [13,[2]],
		"/dashboard/request-a-feature": [14,[2]],
		"/dashboard/sniper": [15,[2]],
		"/dashboard/trade": [16,[2]],
		"/dashboard/wallets": [17,[2]],
		"/guides": [~18,[3]],
		"/guides/[slug]": [19,[3]],
		"/login": [20],
		"/signup": [21]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';